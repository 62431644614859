import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as foodXkAd4FrSzdMeta } from "/vercel/path0/pages/backoffice/food.vue?macro=true";
import { default as foodfamilyK9suPMOT75Meta } from "/vercel/path0/pages/backoffice/foodfamily.vue?macro=true";
import { default as indexXrJ8jvM33vMeta } from "/vercel/path0/pages/backoffice/industrialfoods/[industrialFoodId]/index.vue?macro=true";
import { default as index9pjvF7i7keMeta } from "/vercel/path0/pages/backoffice/industrialfoods/index.vue?macro=true";
import { default as nutrientKbzafZToTXMeta } from "/vercel/path0/pages/backoffice/nutrient.vue?macro=true";
import { default as portionsWzlcN9mnGvMeta } from "/vercel/path0/pages/backoffice/portions.vue?macro=true";
import { default as indexLFF35VIkGuMeta } from "/vercel/path0/pages/backoffice/rawfoods/[rawFoodId]/index.vue?macro=true";
import { default as create5nlxJ7QDE8Meta } from "/vercel/path0/pages/backoffice/rawfoods/create.vue?macro=true";
import { default as indexN5j8ARphKHMeta } from "/vercel/path0/pages/backoffice/rawfoods/index.vue?macro=true";
import { default as add5IDBSjW9HZMeta } from "/vercel/path0/pages/food-survey/add.vue?macro=true";
import { default as indexH5AaUUeSy6Meta } from "/vercel/path0/pages/onboarding/index.vue?macro=true";
import { default as _91analysisId_93e1cBuYbn71Meta } from "/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue?macro=true";
import { default as index18WceqgcrWMeta } from "/vercel/path0/pages/patients/[patientId]/analysis/index.vue?macro=true";
import { default as lastw5c63QoU1PMeta } from "/vercel/path0/pages/patients/[patientId]/analysis/last.vue?macro=true";
import { default as new6loVLlB7zsMeta } from "/vercel/path0/pages/patients/[patientId]/analysis/new.vue?macro=true";
import { default as _91consultationId_938yd7BBCkvdMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue?macro=true";
import { default as indexg3Ki5isTgUMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/index.vue?macro=true";
import { default as lastvlH07Gmw8mMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/last.vue?macro=true";
import { default as newNytUfOQ7MPMeta } from "/vercel/path0/pages/patients/[patientId]/consultations/new.vue?macro=true";
import { default as _91foodSurveyId_93meALH6rGP0Meta } from "/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue?macro=true";
import { default as indexoI12ijbpMJMeta } from "/vercel/path0/pages/patients/[patientId]/food-survey/index.vue?macro=true";
import { default as last3jT7ioZn9UMeta } from "/vercel/path0/pages/patients/[patientId]/food-survey/last.vue?macro=true";
import { default as newcpK6U9BtzYMeta } from "/vercel/path0/pages/patients/[patientId]/food-survey/new.vue?macro=true";
import { default as indexUNGl4Z6FjnMeta } from "/vercel/path0/pages/patients/[patientId]/index.vue?macro=true";
import { default as objectiveshtYCjKQxgLMeta } from "/vercel/path0/pages/patients/[patientId]/objectives.vue?macro=true";
import { default as index7avJUh060AMeta } from "/vercel/path0/pages/patients/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "account___fr",
    path: "/fr/account",
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "account___pt",
    path: "/pt/account",
    component: () => import("/vercel/path0/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "auth___en",
    path: "/en/auth",
    component: () => import("/vercel/path0/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "auth___fr",
    path: "/fr/auth",
    component: () => import("/vercel/path0/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "auth___pt",
    path: "/pt/auth",
    component: () => import("/vercel/path0/pages/auth.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-food___en",
    path: "/en/backoffice/food",
    component: () => import("/vercel/path0/pages/backoffice/food.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-food___fr",
    path: "/fr/backoffice/food",
    component: () => import("/vercel/path0/pages/backoffice/food.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-food___pt",
    path: "/pt/backoffice/food",
    component: () => import("/vercel/path0/pages/backoffice/food.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-foodfamily___en",
    path: "/en/backoffice/foodfamily",
    component: () => import("/vercel/path0/pages/backoffice/foodfamily.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-foodfamily___fr",
    path: "/fr/backoffice/foodfamily",
    component: () => import("/vercel/path0/pages/backoffice/foodfamily.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-foodfamily___pt",
    path: "/pt/backoffice/foodfamily",
    component: () => import("/vercel/path0/pages/backoffice/foodfamily.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-industrialfoods-industrialFoodId___en",
    path: "/en/backoffice/industrialfoods/:industrialFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/industrialfoods/[industrialFoodId]/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-industrialfoods-industrialFoodId___fr",
    path: "/fr/backoffice/industrialfoods/:industrialFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/industrialfoods/[industrialFoodId]/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-industrialfoods-industrialFoodId___pt",
    path: "/pt/backoffice/industrialfoods/:industrialFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/industrialfoods/[industrialFoodId]/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-industrialfoods___en",
    path: "/en/backoffice/industrialfoods",
    component: () => import("/vercel/path0/pages/backoffice/industrialfoods/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-industrialfoods___fr",
    path: "/fr/backoffice/industrialfoods",
    component: () => import("/vercel/path0/pages/backoffice/industrialfoods/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-industrialfoods___pt",
    path: "/pt/backoffice/industrialfoods",
    component: () => import("/vercel/path0/pages/backoffice/industrialfoods/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-nutrient___en",
    path: "/en/backoffice/nutrient",
    component: () => import("/vercel/path0/pages/backoffice/nutrient.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-nutrient___fr",
    path: "/fr/backoffice/nutrient",
    component: () => import("/vercel/path0/pages/backoffice/nutrient.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-nutrient___pt",
    path: "/pt/backoffice/nutrient",
    component: () => import("/vercel/path0/pages/backoffice/nutrient.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-portions___en",
    path: "/en/backoffice/portions",
    component: () => import("/vercel/path0/pages/backoffice/portions.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-portions___fr",
    path: "/fr/backoffice/portions",
    component: () => import("/vercel/path0/pages/backoffice/portions.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-portions___pt",
    path: "/pt/backoffice/portions",
    component: () => import("/vercel/path0/pages/backoffice/portions.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods-rawFoodId___en",
    path: "/en/backoffice/rawfoods/:rawFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/[rawFoodId]/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods-rawFoodId___fr",
    path: "/fr/backoffice/rawfoods/:rawFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/[rawFoodId]/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods-rawFoodId___pt",
    path: "/pt/backoffice/rawfoods/:rawFoodId()",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/[rawFoodId]/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods-create___en",
    path: "/en/backoffice/rawfoods/create",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/create.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods-create___fr",
    path: "/fr/backoffice/rawfoods/create",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/create.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods-create___pt",
    path: "/pt/backoffice/rawfoods/create",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/create.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods___en",
    path: "/en/backoffice/rawfoods",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods___fr",
    path: "/fr/backoffice/rawfoods",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/index.vue").then(m => m.default || m)
  },
  {
    name: "backoffice-rawfoods___pt",
    path: "/pt/backoffice/rawfoods",
    component: () => import("/vercel/path0/pages/backoffice/rawfoods/index.vue").then(m => m.default || m)
  },
  {
    name: "food-survey-add___en",
    path: "/en/food-survey/add",
    meta: add5IDBSjW9HZMeta || {},
    component: () => import("/vercel/path0/pages/food-survey/add.vue").then(m => m.default || m)
  },
  {
    name: "food-survey-add___fr",
    path: "/fr/food-survey/add",
    meta: add5IDBSjW9HZMeta || {},
    component: () => import("/vercel/path0/pages/food-survey/add.vue").then(m => m.default || m)
  },
  {
    name: "food-survey-add___pt",
    path: "/pt/food-survey/add",
    meta: add5IDBSjW9HZMeta || {},
    component: () => import("/vercel/path0/pages/food-survey/add.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___pt",
    path: "/pt",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding___en",
    path: "/en/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding___fr",
    path: "/fr/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "onboarding___pt",
    path: "/pt/onboarding",
    component: () => import("/vercel/path0/pages/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-analysisId___en",
    path: "/en/patients/:patientId()/analysis/:analysisId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-analysisId___fr",
    path: "/fr/patients/:patientId()/analysis/:analysisId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-analysisId___pt",
    path: "/pt/patients/:patientId()/analysis/:analysisId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/[analysisId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis___en",
    path: "/en/patients/:patientId()/analysis",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis___fr",
    path: "/fr/patients/:patientId()/analysis",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis___pt",
    path: "/pt/patients/:patientId()/analysis",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-last___en",
    path: "/en/patients/:patientId()/analysis/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-last___fr",
    path: "/fr/patients/:patientId()/analysis/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-last___pt",
    path: "/pt/patients/:patientId()/analysis/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-new___en",
    path: "/en/patients/:patientId()/analysis/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-new___fr",
    path: "/fr/patients/:patientId()/analysis/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-analysis-new___pt",
    path: "/pt/patients/:patientId()/analysis/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/analysis/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-consultationId___en",
    path: "/en/patients/:patientId()/consultations/:consultationId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-consultationId___fr",
    path: "/fr/patients/:patientId()/consultations/:consultationId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-consultationId___pt",
    path: "/pt/patients/:patientId()/consultations/:consultationId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/[consultationId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations___en",
    path: "/en/patients/:patientId()/consultations",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations___fr",
    path: "/fr/patients/:patientId()/consultations",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations___pt",
    path: "/pt/patients/:patientId()/consultations",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-last___en",
    path: "/en/patients/:patientId()/consultations/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-last___fr",
    path: "/fr/patients/:patientId()/consultations/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-last___pt",
    path: "/pt/patients/:patientId()/consultations/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-new___en",
    path: "/en/patients/:patientId()/consultations/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-new___fr",
    path: "/fr/patients/:patientId()/consultations/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-consultations-new___pt",
    path: "/pt/patients/:patientId()/consultations/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/consultations/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-foodSurveyId___en",
    path: "/en/patients/:patientId()/food-survey/:foodSurveyId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-foodSurveyId___fr",
    path: "/fr/patients/:patientId()/food-survey/:foodSurveyId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-foodSurveyId___pt",
    path: "/pt/patients/:patientId()/food-survey/:foodSurveyId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/[foodSurveyId].vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey___en",
    path: "/en/patients/:patientId()/food-survey",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey___fr",
    path: "/fr/patients/:patientId()/food-survey",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey___pt",
    path: "/pt/patients/:patientId()/food-survey",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-last___en",
    path: "/en/patients/:patientId()/food-survey/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-last___fr",
    path: "/fr/patients/:patientId()/food-survey/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-last___pt",
    path: "/pt/patients/:patientId()/food-survey/last",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/last.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-new___en",
    path: "/en/patients/:patientId()/food-survey/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-new___fr",
    path: "/fr/patients/:patientId()/food-survey/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-food-survey-new___pt",
    path: "/pt/patients/:patientId()/food-survey/new",
    component: () => import("/vercel/path0/pages/patients/[patientId]/food-survey/new.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId___en",
    path: "/en/patients/:patientId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId___fr",
    path: "/fr/patients/:patientId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId___pt",
    path: "/pt/patients/:patientId()",
    component: () => import("/vercel/path0/pages/patients/[patientId]/index.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-objectives___en",
    path: "/en/patients/:patientId()/objectives",
    component: () => import("/vercel/path0/pages/patients/[patientId]/objectives.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-objectives___fr",
    path: "/fr/patients/:patientId()/objectives",
    component: () => import("/vercel/path0/pages/patients/[patientId]/objectives.vue").then(m => m.default || m)
  },
  {
    name: "patients-patientId-objectives___pt",
    path: "/pt/patients/:patientId()/objectives",
    component: () => import("/vercel/path0/pages/patients/[patientId]/objectives.vue").then(m => m.default || m)
  },
  {
    name: "patients___en",
    path: "/en/patients",
    component: () => import("/vercel/path0/pages/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "patients___fr",
    path: "/fr/patients",
    component: () => import("/vercel/path0/pages/patients/index.vue").then(m => m.default || m)
  },
  {
    name: "patients___pt",
    path: "/pt/patients",
    component: () => import("/vercel/path0/pages/patients/index.vue").then(m => m.default || m)
  }
]