<script setup lang="ts">
const localePath = useLocalePath();
</script>

<template>
  <DashboardSideMenuDefault>
    <el-menu-item-group>
      <el-menu-item :index="localePath('backoffice-nutrient')">
        <el-icon>
          <BaseIcon icon="material-symbols:diamond-outline" size="20" />
        </el-icon>
        <template #title> Nutriments </template>
      </el-menu-item>
      <el-menu-item :index="localePath('backoffice-rawfoods')">
        <el-icon>
          <BaseIcon icon="mdi:food-apple" size="20" />
        </el-icon>
        <template #title> Aliments Bruts </template>
      </el-menu-item>
      <el-menu-item :index="localePath('backoffice-industrialfoods')">
        <el-icon>
          <BaseIcon icon="material-symbols:fastfood" size="20" />
        </el-icon>
        <template #title> Aliments Industriels</template>
      </el-menu-item>
      <el-menu-item :index="localePath('backoffice-foodfamily')">
        <el-icon>
          <BaseIcon icon="iconamoon:category-fill" size="20" />
        </el-icon>
        <template #title> Familles d'aliments </template>
      </el-menu-item>

      <el-menu-item :index="localePath('backoffice-portions')">
        <el-icon>
          <BaseIcon icon="material-symbols:scale" size="20" />
        </el-icon>
        <template #title> Portions </template>
      </el-menu-item>
    </el-menu-item-group>
  </DashboardSideMenuDefault>
</template>
